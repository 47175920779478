import React, { useRef, useState } from 'react'
import './dopcommunity.scss'
import VideoCommunity from './VideoCommunity'

const Dopcommunity = () => {

    return (
        <>
            <section className="dopcommunitycards offical-community-links">
                <div className="dopcommunitycardsheader">
                    <p className="dopcommunitycardsheaderpara">
                        <span>02.</span>
                        <span>DOP’s Official communities</span>
                    </p>
                </div>
                <div className="custom-container">
                    <h4 className="communitymainhead">DOP’s Official communities</h4>
                    <div className="inlineflex-btns">
                        <a href="https://t.me/Dop_org" target="_blank" className="btn-launch">
                            <h6>announcements</h6>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M18.4485 4.33593L15.8079 16.7891C15.6086 17.668 15.0891 17.8867 14.3508 17.4726L10.3274 14.5078L8.38599 16.375C8.17115 16.5898 7.99146 16.7695 7.57739 16.7695L7.86646 12.6719L15.3235 5.93359C15.6477 5.64452 15.2532 5.48437 14.8196 5.77343L5.60083 11.5781L1.63208 10.3359C0.768802 10.0664 0.753177 9.47265 1.81177 9.05859L17.3352 3.07812C18.054 2.80859 18.6829 3.23827 18.4485 4.33593Z" fill="white" />
                            </svg>
                        </a>
                        <a href="https://t.me/dop_community" target="_blank" className="btn-launch">
                            <h6>community</h6>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M18.4485 4.33593L15.8079 16.7891C15.6086 17.668 15.0891 17.8867 14.3508 17.4726L10.3274 14.5078L8.38599 16.375C8.17115 16.5898 7.99146 16.7695 7.57739 16.7695L7.86646 12.6719L15.3235 5.93359C15.6477 5.64452 15.2532 5.48437 14.8196 5.77343L5.60083 11.5781L1.63208 10.3359C0.768802 10.0664 0.753177 9.47265 1.81177 9.05859L17.3352 3.07812C18.054 2.80859 18.6829 3.23827 18.4485 4.33593Z" fill="white" />
                            </svg>
                        </a>
                        {/* <a href="https://discord.com/invite/dopofficial" target="_blank" className="btn-launch">
                            <h6>Discord</h6>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M17.044 4.9916C14.7522 3.2766 12.5564 3.3248 12.5564 3.3248L12.332 3.5812C15.0566 4.3986 16.3228 5.6006 16.3228 5.6006C12.4246 3.4544 7.45939 3.4698 3.40479 5.6006C3.40479 5.6006 4.71899 4.3344 7.60399 3.517L7.44379 3.3246C7.44379 3.3246 5.26399 3.2766 2.95619 4.9914C2.95619 4.9914 0.648193 9.1424 0.648193 14.2552C0.648193 14.2552 1.99459 16.5632 5.53659 16.6754C5.53659 16.6754 6.12959 15.9702 6.61039 15.3612C4.57499 14.7522 3.80559 13.486 3.80559 13.486C5.08439 14.2862 6.37739 14.787 7.98879 15.1048C10.6104 15.6444 13.8714 15.0896 16.307 13.486C16.307 13.486 15.5056 14.7842 13.406 15.3772C13.8868 15.9702 14.4638 16.6594 14.4638 16.6594C18.0058 16.5472 19.352 14.2392 19.352 14.2554C19.3518 9.1426 17.044 4.9916 17.044 4.9916ZM7.01099 12.7648C6.11339 12.7648 5.37619 11.9794 5.37619 11.0018C5.44179 8.6604 8.59659 8.6676 8.64579 11.0018C8.64579 11.9794 7.92439 12.7648 7.01099 12.7648ZM12.8608 12.7648C11.9632 12.7648 11.226 11.9794 11.226 11.0018C11.298 8.665 14.4134 8.664 14.4956 11.0018C14.4956 11.9794 13.7744 12.7648 12.8608 12.7648Z" fill="white" />
                            </svg>
                        </a> */}
                        <a href="https://twitter.com/dop_org" target="_blank" className="btn-launch">
                            <h6>X</h6>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M15.2033 1.875H17.96L11.9375 8.75833L19.0225 18.125H13.475L9.13 12.4442L4.15833 18.125H1.4L7.84167 10.7625L1.045 1.875H6.73333L10.6608 7.0675L15.2033 1.875ZM14.2358 16.475H15.7633L5.90333 3.43833H4.26417L14.2358 16.475Z" fill="white" />
                            </svg>
                        </a>
                    </div>
                </div>
            </section>
            <section className="dopcommunitycards">
                <div className="dopcommunitycardsheader">
                    <p className="dopcommunitycardsheaderpara">
                        <span>03.</span>
                        <span>DOP Community</span>
                    </p>
                </div>
                <div className="dopcommunityvideo">
                    <div className="custom-container">
                        <VideoCommunity />
                    </div>
                </div>
                <div className="custom-container">
                    <h4 className="communitymainhead">DOP communities worldwide</h4>
                    <div className="communitymain">
                        <div class="communityinner">
                            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="\assets\community\turkey.svg" alt="communityimg" className="communityimg" />
                                <h6 className="communityinnerpara">Turkish</h6>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path d="M11.3334 9.20837L8.50002 12.0417L5.66669 9.20837M11.3334 4.95837L8.50002 7.79171L5.66669 4.95837" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="https://t.me/DOP_Turkiye_community" target='_blank'>Official Community</a></li>
                                <li><a class="dropdown-item" href="https://t.me/DOP_Turkiye" target='_blank'>Telegram Announcement</a></li>
                            </ul>
                        </div>
                        <a href="https://t.me/Dop_official_Spanish" target='_blank' className="communityinner">
                            <img src="\assets\community\spain.svg" alt="communityimg" className="communityimg" />
                            <h6 className="communityinnerpara">Spanish</h6>
                        </a>
                        <a href="https://t.me/Dop_official_Japan" target='_blank' className="communityinner">
                            <img src="\assets\community\japan.svg" alt="communityimg" className="communityimg" />
                            <h6 className="communityinnerpara">Japanese</h6>
                        </a>
                        <a href="https://t.me/DOP_kr_community" target='_blank' className="communityinner">
                            <img src="\assets\community\korea-sign.svg" alt="communityimg" className="communityimg" />
                            <h6 className="communityinnerpara">Korean</h6>
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dopcommunity